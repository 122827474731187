<template>
  <div>
    <div class="d-flex flex-column flex-root">
      <div class="container-fluid" style="margin-bottom: 20px">
        <div class="background-custom px-6 py-10">
          <div class="row align-items-center">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body p-2 text-center">
                      <i class="font-weight-bold text-dark">Peserta</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="background-input p-6">
                <div class="card">
                  <div class="card-body px-3 py-2">
                    <div
                      class="
                        row
                        no-gutters
                        justify-content-between
                        align-items-center
                      "
                    >
                      <div class="">
                        <i class="font-weight-bold text-dark">Skor</i>
                      </div>
                      <div class="">
                        <b-form-input
                          id="input-feedback"
                          class="
                            form-control form-control-sm
                            bg-secondary
                            w-50
                            ml-auto
                          "
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="row no-gutters mt-5">
                      <i class="d-block font-weight-bold text-dark">Feedback</i>
                      <b-form-textarea
                        id="input-feedback"
                        class="bg-secondary hidden-overflow"
                        rows="4"
                        max-rows="8"
                        style="
                          resize: none;
                          overflow-y: hidden !important;
                          overflow: hidden !important;
                          height: 233px;
                          border-radius: 6px;
                        "
                      ></b-form-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.background-custom {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 21px;
}

.background-input {
  background-color: #ff0000;
  border-radius: 21px;
}

.hidden-overflow{
    overflow: hidden !important;
    overflow-y: hidden !important;
}
</style>